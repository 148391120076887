import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import { PrivacyWrapper } from "./styled.components"
import Title from "../../components/Title"

const PrivacyContainer = props => {
  return (
    <PrivacyWrapper>
      <Title title="Privacy Policy" subtitle="We are here to help" />

      <div className="container">
        <div className="main_section">
          <h2>Effective date April 22, 2021.</h2>
          <p>
            This Privacy Policy describes the privacy practices of Disvovr. and
            our subsidiaries and affiliates (collectively, “Discovr”, “we”,
            “us”, or “our”) in connection with the https://joindiscovr.com/
            website, the Discovr mobile application, and any other website or
            mobile application that we own or control and which posts or links
            to this Privacy Policy (collectively, the “Service”), and the rights
            and choices available to individuals with respect to their
            information.
          </p>
          <h3>Personal Information We Collect</h3>
          <p>
            Information you provide to us. Personal information you may provide
            to us through the Service or otherwise includes:
          </p>
          <ul>
            <li>
              Personal contact information, such as your first and last name and
              email address.
            </li>
            <li>
              User generated information, such as information that you upload,
              generate, transmit, or otherwise make available on the Service,
              such as photos, videos, images, music, comments, questions,
              messages, your “likes”, as well as associated metadata. Metadata
              includes information on how, when, where and by whom a piece of
              content was created or collected, how that content has been
              formatted or edited, and the location associated with the creation
              of the content.
            </li>
            <li>
              Profile information, such as the username that you may set to
              establish an online account with us, password, profile pictures,
              links to your profiles on social networks, interests, preferences,
              occupation, employers, title, position, employment function,
              promotions, or surveys, and any other information that you add to
              your account profile.
            </li>
            <li>
              Feedback or correspondence, such as information you provide when
              you contact us with questions, feedback, or otherwise correspond
              with us online.
            </li>
            <li>
              Usage information, such as information about how you use the
              Service and interact with us, including information associated
              with any content you upload to the websites or otherwise submit to
              us, and information you provide when you use any interactive
              features of the Service.
            </li>
            <li>
              Marketing information, such as your preferences for receiving
              communications about our activities, events, and publications, and
              details about how you engage with our communications.
            </li>
            <li>
              Other information that we may collect which is not specifically
              listed here, but which we will use in accordance with this Privacy
              Policy or as otherwise disclosed at the time of collection.
            </li>
          </ul>
          <p>
            Information we obtain from social media platforms. We may maintain
            pages for our Company on social media platforms, such as Facebook,
            LinkedIn, Twitter, Google, YouTube, Instagram, and other third party
            platforms. When you visit or interact with our pages on those
            platforms, the platform provider's privacy policy will apply to your
            interactions and their collection, use and processing of your
            personal information. You or the platforms may provide us with
            information through the platform, and we will treat such information
            in accordance with this Privacy Policy.
          </p>
          <p>
            If you choose to login to the Service via a third-party platform or
            social media network, or otherwise connect your account on the
            third-party platform or network to your account through the Service,
            we may collect information from that platform or network. For
            example, this information may include your Facebook username, user
            ID, profile picture, cover photo, and networks to which you belong
            (e.g., school, workplace). You may also have the opportunity to
            provide us with additional information via the third-party platform
            or network, such as a list of your friends or connections and your
            email address. You can read more about your privacy choices in the
            “Third party platforms or social media networks” portion of the
            “Your Choices” section.
          </p>
          <p>
            Information we obtain from other third parties. We may receive
            personal information about you from third-party sources. For
            example, a business partner may share your contact information with
            us if you have expressed interest in learning specifically about our
            products or services, or the types of products or services we offer.
            We may obtain your personal information from other third parties,
            such as marketing partners, publicly-available sources and data
            providers.
          </p>
          <p>
            Cookies and Other Information Collected by Automated Means. We, our
            service providers, and our business partners may automatically log
            information about you, your computer or mobile device, and activity
            occurring on or through the Service, including but not limited, your
            computer or mobile device operating system type and version number,
            manufacturer and model, device identifier (such as the Google
            Advertising ID or Apple ID for Advertising), browser type, screen
            resolution, IP address, the website you visited before browsing to
            our website, general location information such as city, state or
            geographic area; information about your use of and actions on the
            Service, such as pages or screens you viewed, how long you spent on
            a page or screen, navigation paths between pages or screens,
            information about your activity on a page or screen, access times,
            and length of access; and other personal information. Our service
            providers and business partners may collect this type of information
            over time and across third-party websites and mobile applications.
          </p>
          <p>
            On our webpages, this information is collected using cookies,
            browser web storage (also known as locally stored objects, or
            “LSOs”), web beacons, and similar technologies, and our emails may
            also contain web beacons. In our mobile application, we may collect
            this information directly or through our use of third-party software
            development kits (“SDKs”). SDKs may enable third parties to collect
            information directly from our App.
          </p>
          <p>
            A “cookie” is a text file that websites send to a visitor's computer
            or other Internet-connected device to uniquely identify the
            visitor's browser or to store information or settings in the
            browser. Browser web storage, or LSOs, are used for similar purposes
            as cookies. Browser web storage enables the storage of a larger
            amount of data than cookies. A “web beacon,” also known as a pixel
            tag or clear GIF, is typically used to demonstrate that a webpage
            was accessed or that certain content was viewed, typically to
            measure the success of our marketing campaigns or engagement with
            our emails and to compile statistics about usage of our websites. A
            SDK is third-party computer code that we may incorporate into our
            mobile applications that may be used for a variety of purposes,
            including to provide us with analytics regarding the use of our
            mobile applications, to integrate with social media, add features or
            functionality to our app, or to facilitate online advertising.
          </p>
          <p>
            Web browsers may offer users of our websites or mobile apps the
            ability to disable receiving certain types of cookies; however, if
            cookies are disabled, some features or functionality of our websites
            may not function correctly. Please see the “Targeted online
            advertising” section for information about how to exercise choice
            regarding the use of browsing behavior for purposes of targeted
            advertising.
          </p>
          <p>
            For information regarding your choices regarding the use of cookies
            and similar technologies on the Service, see the “Your Choices”
            section below.
          </p>
          <p>
            Referrals. Users of the Service may have the opportunity to refer
            friends or other contacts to us. If you are an existing user, you
            may only submit a referral if you have permission to provide the
            referral's contact information to us so that we may contact them.
          </p>
          <p>
            Retention. We will retain your personal information for the period
            necessary for the purposes outlined in this Privacy Policy unless a
            longer retention period is required or permitted by law.
          </p>
          <p>
            The categories of personal information listed above may be linkable
            to your identity by us and the third parties described below in How
            we share your personal information.
          </p>
          <h3>How We Use Your Personal Information</h3>
          <p>
            We use your personal information for the following purposes or as
            otherwise described at the time of collection: To operate the
            Service. We use your personal information to:
          </p>
          <ul>
            <li>provide, operate and improve the Service</li>
            <li>provide information about our products and services</li>
            <li>establish and maintain your user profile on the Service</li>
            <li>
              facilitate your to login to the Service via third-party identity
              and access management providers, such as Facebook
            </li>
            <li>
              enable security features of the Service, such as by sending you
              security codes via email or SMS, and remembering devices from
              which you have previously logged in
            </li>
            <li>
              facilitate social features of the Service, such as by identifying
              and suggesting connections with other users of the Service and
              providing chat or messaging functionality
            </li>
            <li>
              communicate with you about the Service, including by sending you
              announcements, updates, security alerts, and support and
              administrative messages
            </li>
            <li>
              understand your needs and interests, and personalize your
              experience with the Service and our communications
            </li>
            <li>provide support and maintenance for the Service</li>
            <li>to respond to your requests, questions and feedback</li>
          </ul>
          <p>
            For research and development. We analyze use of the Service to
            analyze and improve the Service and to develop new products and
            services, including by studying user demographics and use of the
            Service.
          </p>
          <p>
            To send you marketing and promotional communications. We may send
            you [Company]-related marketing communications as permitted by law.
            You will have the ability to opt-out of our marketing and
            promotional communications as described in the Opt out of marketing
            section below.
          </p>
          <p>
            To display advertisements. We work with advertising partners to
            display advertisements on the Service or elsewhere online. These
            advertisements are delivered by our advertising partners and may be
            targeted based on your use of the Service or your activity elsewhere
            online. To learn more about your choices in connection with
            advertisements, please see the section below titled “Targeted online
            advertising.”
          </p>
          <p>
            To comply with law. We use your personal information as we believe
            necessary or appropriate to comply with applicable laws, lawful
            requests, and legal process, such as to respond to subpoenas or
            requests from government authorities.
          </p>
          <p>
            With your consent. In some cases we may specifically ask for your
            consent to collect, use or share your personal information, such as
            when required by law.
          </p>
          <p>
            To create anonymous, aggregated or de-identified data. We may create
            anonymous, aggregated or de-identified data from your personal
            information and other individuals whose personal information we
            collect. We make personal information into anonymous, aggregated or
            de-identified data by removing information that makes the data
            personally identifiable to you. We may use this anonymous,
            aggregated or de-identified data and share it with third parties for
            our lawful business purposes, including to analyze and improve the
            Service and promote our business.
          </p>
          <h3>How We Share Your Personal Information</h3>
          <p>
            We do not share your personal information with third parties without
            your consent, except in the following circumstances or as described
            in this Privacy Policy:
          </p>
          <p>
            Affiliates. We may share your personal information with our
            corporate parent, subsidiaries, and affiliates, for purposes
            consistent with this Privacy Policy.
          </p>
          <p>
            Service providers. We may share your personal information with third
            party companies and individuals that provide services on our behalf
            or help us operate the Service (such as customer support, hosting,
            analytics, email delivery, marketing, and database management
            services). These third parties may use your personal information
            only as directed or authorized by us and in a manner consistent with
            this Privacy Policy, and are prohibited from using or disclosing
            your information for any other purpose.
          </p>
          <p>
            Advertising partners. We may share your personal information with
            third party advertisers and advertising companies for the
            advertising purposes described above. Advertisers whose ads are
            posted on our Service may be able to infer information about you
            when you click on those ads (e.g., that you have a newborn if you
            click on an ad about a newborn product).
          </p>
          <p>
            Third-party platforms and social media networks. We may share your
            personal information with third party platforms and social media
            networks if you have enabled features or functionality that connect
            the Service to a third-party platform or social media network (such
            as by logging in to the Service using your account with the
            third-party, providing your API key or similar access token for the
            Service to a third party, or otherwise linking your account with the
            Service to a third party's services), we may disclose the personal
            information that you authorized us to share. We do not control the
            third party's use of your personal information.
          </p>
          <p>
            Other users and the public. We may provide functionality that
            enables you to disclose personal information to other users of the
            Service or the public. For instance, you may be able to maintain a
            user profile with information about yourself and your brokerage
            accounts that you can make available to other users or the public.
            You may also be able to submit content to the Service (such as
            comments, reviews, surveys, blogs, photos, and videos), and we will
            display your name, username, and a link to your user profile along
            with the content you submit. We may make available settings through
            the 'Privacy' tab within the 'Accounting Settings' page that enable
            you to exercise choice regarding certain information that is
            displayed publicly or to other users. We do not control how other
            users or third parties use any personal information that you make
            available to such users or the public.
          </p>
          <p>
            Professional advisors. We may disclose your personal information to
            professional advisors, such as lawyers, bankers, auditors and
            insurers, where necessary in the course of the professional services
            that they render to us.
          </p>
          <p>
            For compliance, fraud prevention and safety. We may share your
            personal information for the compliance, fraud prevention and safety
            purposes described above.
          </p>
          <p>
            Business transferees. We may sell, transfer or otherwise share some
            or all of our business or assets, including your personal
            information, in connection with a business transaction (or potential
            business transaction) such as a corporate divestiture, merger,
            consolidation, acquisition, reorganization or sale of assets, or in
            the event of bankruptcy or dissolution.
          </p>
          <h3>Your Choices</h3>
          <p>
            In this section, we describe the rights and choices available to all
            users.
          </p>
          <p>
            Access or Update Your Information. If you have registered for an
            account with us, you may review and update certain personal
            information in your account profile by logging into the account.
          </p>
          <p>
            Opt out of marketing communications. You may opt out of
            marketing-related emails by following the opt-out or unsubscribe
            instructions at the bottom of the email. You may continue to receive
            service-related and other non-marketing emails.
          </p>
          <p>
            Cookies & Browser Web Storage. We may allow service providers and
            other third parties to use cookies and similar technologies to track
            your browsing activity over time and across the Service and third
            party websites. Most browsers let you remove or reject cookies. To
            do this, follow the instructions in your browser settings. Many
            browsers accept cookies by default until you change your settings.
            Please note that if you set your browser to disable cookies, the
            Sites may not work properly. Similarly, your browser settings may
            allow you to clear your browser web storage.
          </p>
          <p>
            Privacy settings and location data. Users of our mobile application
            have the choice whether to allow us to access your precise location
            data. Your device settings may provide the ability for you to revoke
            our ability to access location data.
          </p>
          <p>
            Targeted online advertising. Some of the business partners that
            collect information about users' activities on or through the
            Service may be members of organizations or programs that provide
            choices to individuals regarding the use of their browsing behavior
            or mobile application usage for purposes of targeted advertising.
          </p>
          <p>
            In addition, your mobile device settings may provide functionality
            to limit our, or our partners', ability to engage in ad tracking or
            targeted advertising using the Google Advertising ID or Apple ID for
            Advertising associated with your mobile device.
          </p>
          <p>
            If you choose to opt-out of targeted advertisements, you will still
            see advertisements online but they may not be relevant to you. Even
            if you do choose to opt out, not all companies that serve online
            behavioral advertising are included in this list, so you may still
            receive some cookies and tailored advertisements from companies that
            are not listed.
          </p>
          <p>
            Do Not Track. Some Internet browsers may be configured to send “Do
            Not Track” signals to the online services that you visit. We
            currently do not respond to “Do Not Track” or similar signals. To
            find out more about “Do Not Track,” please visit{" "}
            <a href="http://www.allaboutdnt.com">http://www.allaboutdnt.com</a>.
          </p>
          <p>
            Choosing not to share your personal information. Where we are
            required by law to collect your personal information, or where we
            need your personal information in order to provide the Service to
            you, if you do not provide this information when requested (or you
            later ask to delete it), we may not be able to provide you with our
            services. We will tell you what information you must provide to
            receive the Service by designating it as required at the time of
            collection or through other appropriate means.
          </p>
          <p>
            Third-party platforms or social media networks. If you choose to
            connect to the Service via a third-party platform or social media
            network, you may have the ability to limit the information that we
            may obtain from the third-party at the time you login to the Service
            using the third-party's authentication service or otherwise connect
            your account. Subsequently, you may be able to control your settings
            through the third-party's platform or service. For example, you may
            access and change your settings through the Facebook settings page
            for Apps and Websites. If you withdraw our ability to access certain
            information from a third-party platform or social media network,
            that choice will not apply to information that we have already
            received from that third-party.
          </p>
          <p>
            Delete your content or close your account. You can choose to delete
            certain content through your account. If you wish to request to
            close your account, please contact us.
          </p>
          <h3>Other Sites, Mobile Applications and Services</h3>
          <p>
            The Service may contain links to other websites, mobile
            applications, and other online services operated by third parties.
            These links are not an endorsement of, or representation that we are
            affiliated with, any third party. In addition, our content may be
            included on web pages or in mobile applications or online services
            that are not associated with us. We do not control third party
            websites, mobile applications or online services, and we are not
            responsible for their actions. Other websites, mobile applications
            and services follow different rules regarding the collection, use
            and sharing of your personal information. We encourage you to read
            the privacy policies of the other websites, mobile applications and
            online services you use.
          </p>
          <h3>Security Practices</h3>
          <p>
            The security of your personal information is important to us. We
            employ a number of organizational, technical and physical safeguards
            designed to protect the personal information we collect. However,
            security risk is inherent in all internet and information
            technologies and we cannot guarantee the security of your personal
            information.
          </p>
          <h3>International Data Transfer</h3>
          <p>
            We are headquartered in the United States and may use service
            providers that operate in other countries. Your personal information
            may be transferred to the United States or other locations where
            privacy laws may not be as protective as those in your state,
            province, or country.
          </p>
          <h3>Children</h3>
          <p>
            The Service is not intended for use by anyone under 13 years of age.
            If we learn that we have collected personal information through the
            Service from a child under 13 without the consent of the child's
            parent or guardian as required by law, we will delete it. We
            encourage parents with concerns to contact us.
          </p>
          <h3>Changes to this Privacy Policy </h3>
          <p>
            We reserve the right to modify this Privacy Policy at any time. If
            we make material changes to this Privacy Policy, we will notify you
            by updating the date of this Privacy Policy and posting it on the
            Service. We may, and if required by law will, also provide
            notification of changes in another way that we believe is reasonably
            likely to reach you, such as via e-mail (if you have an account
            where we have your contact information) or another manner through
            the Service.
          </p>
          <p>
            Any modifications to this Privacy Policy will be effective upon our
            posting the new terms and/or upon implementation of the new changes
            on the Service (or as otherwise indicated at the time of posting).
            In all cases, your continued use of the Service after the posting of
            any modified Privacy Policy indicates your acceptance of the terms
            of the modified Privacy Policy.
          </p>
          <h3>How to Contact Us</h3>
          <p>
            Please direct any questions or comments about this Policy or privacy
            practices to zmmeyer44@gmail.com.
          </p>
        </div>
      </div>
    </PrivacyWrapper>
  )
}

export default PrivacyContainer
