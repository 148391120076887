import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Privacy from "../containers/Privacy"

const PrivacyPolicyPage = () => {
  return (
    <Layout style={{ zIndex: 1 }}>
      <Seo title="Privacy Policy" />
      <Privacy />
    </Layout>
  )
}

export default PrivacyPolicyPage
